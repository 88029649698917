import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import ColumnRelationLink from './columnRelationLink'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0024 = (props: Props): JSX.Element => {
  const routePram = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0025/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0024'
  )
  const routePram2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0021/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0024'
  )
  const routePram3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0022/?utm_source=article&utm_medium=post&utm_campaign=Related_article_C0024'
  )

  return (
    <Wrapper>
      <ColumnH1 label="時間単位と半日単位の有給休暇とは？制度内容と導入方法を解説" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2022.04.15</p>
      <section>
        <img src="/images/column/details/c0024.jpg" alt="キンクラコラム" />
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <p>
          会社で働く労働者にとっても注目度の高い制度の一つに有給休暇が挙げられます。
          <span>有給休暇</span>
          は本来、暦日を単位として取得することが原則とされていますが、取得率が進まない背景があり、時間単位や半日単位での取得も法律、通達で可能と明記されています。
          <br />
          今回は時間単位の有給休暇、半日単位の有給休暇にフォーカスをあて、解説してまいります。
        </p>
        <div className="toc">
          <p>目次</p>
          <ol>
            <li>
              <a href="#toc1">時間単位の有給休暇</a>
            </li>
            <li>
              <a href="#toc2">
                時間単位の有給休暇導入に当たり必要な労使協定の協定内容
              </a>
            </li>
            <li>
              <a href="#toc3">労使協定締結に当たっての留意点</a>
            </li>
            <li>
              <a href="#toc4">時間単位有給休暇の実務上の取得</a>
            </li>
            <li>
              <a href="#toc5">半日単位の有給休暇</a>
            </li>
            <li>
              <a href="#toc6">年5日取得義務における双方の留意点</a>
            </li>
            <li>
              <a href="#toc7">最後に</a>
            </li>
          </ol>
        </div>
        <div id="toc1">
          <h1>時間単位の有給休暇</h1>
        </div>
        <p>
          労働組合がない会社の場合、労働者の過半数を代表する者と労使協定を締結することで法律上は
          <span>5日以内に限り時間単位の有給休暇</span>
          を付与することができます。労使協定締結にあたっては、時間単位の有給休暇を与えることができる労働者の範囲を決定します。
          <br />
          <br />
          尚、労使協定は所轄労働基準監督署への届出は不要とされ、与えることが出来る日数の「5日以内」とは、
          <span>前年度の繰り越し分も含めて</span>
          5日の範囲内となります。5日に満たない日数の有給休暇が付与される労働者については労使協定で付与される日数の範囲内で定めることとなります。
          <br />
          <br />
          時間単位の有給休暇について、令和2年就労条件総合調査（厚生労働省）結果では令和元年の有給休暇取得率は男女平均56.3%（男性53.7％・女性60.7％）となっており、決して多いとは言い切りません。過去を紐解くと平成21年に有給休暇の取得促進を期待し、有給休暇の本来の趣旨を踏まえつつ、仕事と生活の調和を図る観点から労使協定により5日の範囲内で（平成22年4月1日施行）時間単位の有給休暇を与える旨の通達が出されています。
        </p>
        <div id="toc2">
          <h1>時間単位の有給休暇導入に当たり必要な労使協定の協定内容</h1>
        </div>
        <p>
          実務上、時間単位の有給休暇を制度化するには労使協定の締結が必要であり、締結内容は以下のとおりです。
          <br />
          <br />
          ・時間単位の有給休暇の対象とする労働者の範囲
          <br />
          ・時間単位の有給休暇の日数（5日以内に限る）
          <br />
          ・その他厚生労働省令で定める事項（1時間以外の時間を単位とする場合はその時間数等）
        </p>
        <div id="toc3">
          <h1>労使協定締結に当たっての留意点</h1>
        </div>
        <p>
          例えば労働組合員のみ認めないこと、妊産婦労働者のみ認めないという範囲の決定は明らかに問題ですが、流れ作業に従事する工場労働者に限って時間単位の有給休暇の取得が困難であることから対象に含めないという考え方であれば直ちに違法とはなりません。
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc4">
          <h1>時間単位有給休暇の実務上の取得</h1>
        </div>
        <p>
          実際に時間単位の有給休暇を取得するか否かは個々の労働者の意志によります。また、実務上、1日分の有給休暇が何時間分の時間単位に相当するのかは個々の労働者の所定労働時間によって変わります。
          <br />
          尚、例外的に所定労働時間に1時間に満たない時間数がある場合、労働者にとって不利益とならないようにする観点から1日の所定労働時間を下回らないものとすることが求められます（1時間に満たない時間数は1時間に切り上げ）。
          <br />
          他の論点として、日によって1日の所定労働時間数が異なる場合は1年間における1日平均所定労働時間数を基準とし、1年間の総所定労働時間が決まっていない場合は所定労働時間が決まっている期間における1日平均所定労働時間数が基準となります。
          <br />
          <br />
          また、2時間や3時間といった「1時間以外」の時間を単位として時間単位の有給休暇を設定する場合は
          <span>労使協定にてその旨を定める</span>
          必要があります。そして、時間単位の有給休暇についても使用者の時季変更権の対象となります。
          <br />
          注意点としては労働者が時間単位の有給休暇を請求した場合に日単位の有給休暇に変更することや、日単位の有給休暇を時短単位の有給休暇に変更することは時季変更にはあたらず、認められません。
          <br />
          また、労使協定において、予め時間単位の有給休暇を取得できない時間帯を定めておくこと、所定労働時間の途中に時間単位の有給休暇を取得することを制限すること、取得できる時間単位の有給休暇の時間数を制限するなどの定めも認められません。
          <br />
          <br />
          時間単位の有給休暇と計画的付与の関係については、時間単位の有給休暇はあくまで労働者が時間単位による有給休暇の取得を請求した場合に与えることでできるものであり、計画的付与として時間単位の有給休暇を付与することはできません。
        </p>
        <ColumnRelationLink
          link={routePram}
          label="こんな時に使いたい！時間単位の有給休暇の活用事例と注意事項"
        />
        <div id="toc5">
          <h1>半日単位の有給休暇</h1>
        </div>
        <p>
          有給休暇の更なる取得促進の観点から労働者が半日単位の有給休暇を希望し、時季を指定し、かつ、使用者が同意した場合であり、本来の取得方法の阻害とならない範囲で適切に運用されることを前提として半日単位の有給休暇を付与しても問題はありません。当然、労働者が日単位で有給休暇を指定しているにも関わらず使用者が半日単位の有給休暇を付与することはできません。
          <br />
          <br />
          実務上しばしば問題になる部分として、例えば土曜日は半日のみ勤務の労働者が土曜日に有給休暇を取得した場合は丸一日出勤した場合と同様に1労働日分の有給休暇を取得したことになります。しかし、半日単位の取得として土曜日に2回有給休暇を取得した場合に1日分の有給休暇を取得したとすることは総合的に労働者の不利益にはならず、法を上回る対応であることから違法とはなりません。
        </p>
        <div id="toc6">
          <h1>年5日取得義務における双方の留意点</h1>
        </div>
        <p>
          2019年の改正労働基準法により年10日以上の有給休暇が付与される労働者に対しては、付与された日から1年以内に5日の有給休暇を付与することが求められています。万が一5日付与することができなかった場合には罰則も設けられており、1人につき30万円以下の罰金が法律上規定されています。そこで、時間単位と半日単位の有給休暇を取得した場合の関係性を整理しましょう。
          <br />
          <br />
          結論としては時間単位の有給休暇を取得しても取得すべき5日の中に含めることはできませんが、
          <span>半日単位は0.5日分</span>として含めることができます。
        </p>
        <ColumnRelationLink
          link={routePram2}
          label="年次有給休暇とは？付与の条件や日数、時期などを詳しく解説"
        />
        <ColumnRelationLink
          link={routePram3}
          label="有給休暇の取得義務化！企業がするべき対応やポイントとは"
        />
        <div id="toc7">
          <h1 className="read">最後に</h1>
        </div>
        <p>
          働き方の多様化が推し進められる現代において、日単位よりもむしろ時間単位や半日単位の方が取得促進に繋がるという企業もあるでしょう。特に年5日の取得義務には罰則が設けられたこともあり、時間単位と半日単位の相違点は重要な論点となります。
          <br />
          しかし、それ以前に有給休暇を取得することで
          <span>心身のリフレッシュ</span>
          を図り、<span>高い労働生産性を維持</span>
          するためにも有給休暇を取得しやすい風土づくりに注力していくことが重要です。
        </p>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0024
